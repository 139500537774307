import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Embedding Polis on your web property`}</h1>
    <ul>
      <li parentName="ul">{`First, you'll need to create an account or sign in, and then start a `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` from the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{` if you haven't already`}</li>
      <li parentName="ul">{`Once you have a `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{`, in the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{`, head to `}<a parentName="li" {...{
          "href": "/distribute",
          "title": "distribute"
        }}>{`distribute`}</a>{``}</li>
      <li parentName="ul">{`Grab the `}<a parentName="li" {...{
          "href": "/iframe",
          "title": "iframe"
        }}>{`iframe`}</a>{` `}<a parentName="li" {...{
          "href": "/embed-code",
          "title": "embed code"
        }}>{`embed code`}</a>{` on the `}<a parentName="li" {...{
          "href": "/distribute",
          "title": "distribute"
        }}>{`distribute`}</a>{` tab`}</li>
      <li parentName="ul">{`Drop it on your site`}</li>
      <li parentName="ul">{`It's responsive!`}</li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "/embed-code",
          "title": "embed code"
        }}>{`embed code`}</a>{` for configuration options available from the widget.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      